<template>
  <!-- 共享表单页 -->
  <div style="background: #f8f8f8">
    <div class="cson-container">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>提供信息</el-breadcrumb-item>
        <el-breadcrumb-item v-if="type == 'newCompany'"
          >提供新的公司</el-breadcrumb-item
        >
        <el-breadcrumb-item v-else-if="type == 'newResource'"
          >提供求职最新信息</el-breadcrumb-item
        >
        <el-breadcrumb-item v-else-if="type == 'editResource'"
          >补充更正已有公司信息</el-breadcrumb-item
        >
      </el-breadcrumb>
      <div class="formContainer">
        <div class="formDetail">
          <div class="title" v-if="type == 'newCompany'">提供新的公司</div>
          <div class="title" v-if="type == 'newResource'">提供求职最新信息</div>
          <div class="title" v-if="type == 'editResource'">
            补充更正已有公司信息
          </div>
          <el-form
            v-if="type == 'newCompany'"
            :modal="newCompanyForm"
            label-width="150px"
          >
            <el-form-item label="公司名字" required>
              <el-input
                v-model="newCompanyForm.name"
                placeholder="请输入公司名"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司地址">
              <el-input
                v-model="newCompanyForm.address"
                placeholder="请输入公司地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="New Grad Link">
              <el-input
                type="number"
                v-model="newCompanyForm.newGradLink"
                placeholder="请输入NewGrad招聘链接"
              ></el-input>
            </el-form-item>
            <el-form-item label="Internship Link">
              <el-input
                type="number"
                v-model="newCompanyForm.internshipLink"
                placeholder="请输入Internship招聘链接"
              ></el-input>
            </el-form-item>
            <el-form-item label="Career Link">
              <el-input
                type="number"
                v-model="newCompanyForm.careerLink"
                placeholder="请输入Carrer招聘链接"
              ></el-input>
            </el-form-item>
            <el-form-item label="New Grad Salary/y">
              <el-input
                v-model="newCompanyForm.newGradSalary"
                placeholder="请输入NewGrad年薪"
              ></el-input>
            </el-form-item>
            <el-form-item label="Internship Salary/m">
              <el-input
                v-model="newCompanyForm.internshipMonthlySalary"
                placeholder="请输入Internship月薪"
              ></el-input>
            </el-form-item>
            <el-form-item label="Internship Salary/h">
              <el-input
                v-model="newCompanyForm.internshipHourlySalary"
                placeholder="请输入Internship时薪"
              ></el-input>
            </el-form-item>
            <el-form-item label="其他信息">
              <el-input
                v-model="newCompanyForm.additionalRemarks"
                placeholder="请输入其它信息"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 20 }"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-form
            v-if="type == 'newResource'"
            :modal="newResourceForm"
            label-width="150px"
          >
            <el-form-item label="公司名字" required>
              <el-autocomplete
                v-model="searchCompanyKey"
                :fetch-suggestions="querySearch"
                :trigger-on-focus="false"
                placeholder="输入公司名"
                :popper-append-to-body="false"
                @input="searchLodash"
                @select="selectCompany"
                @keydown="editResourceForm.companyId = ''"
                @keydown.native.enter="selectDefaultCompany"
                @blur="blurEvents('newResource')"
                style="width: 100%"
              >
                <template slot-scope="{ item }" v-if="companys.length > 0">
                  {{ item.name }}
                </template>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="最新信息" required>
              <el-radio-group v-model="newResourceForm.jobType" size="small">
                <el-radio :label="0" border>Internship</el-radio>
                <el-radio :label="1" border>New Grad</el-radio>
                <el-radio :label="2" border>在职跳槽</el-radio>
              </el-radio-group>
              <el-input
                v-model="newResourceForm.content"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 20 }"
                style="margin-top: 12px"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-form
            v-if="type == 'editResource'"
            :modal="editResourceForm"
            label-width="150px"
          >
            <el-form-item label="公司" required>
              <el-autocomplete
                v-model="searchCompanyKey"
                :fetch-suggestions="querySearch"
                :trigger-on-focus="false"
                placeholder="输入公司名"
                :popper-append-to-body="false"
                @input="searchLodash"
                @select="selectCompany"
                @keydown="editResourceForm.companyId = ''"
                @keydown.native.enter="selectDefaultCompany"
                @blur="blurEvents('edit')"
                style="width: 100%"
              >
                <template slot-scope="{ item }" v-if="companys.length > 0">
                  {{ item.name }}
                </template>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="公司地址">
              <el-input
                v-model="editResourceForm.address"
                placeholder="请输入公司地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="New Grad Link">
              <el-input
                v-model="editResourceForm.newGradLink"
                placeholder="请输入NewGrad招聘链接"
              ></el-input>
            </el-form-item>
            <el-form-item label="Internship Link">
              <el-input
                v-model="editResourceForm.internshipLink"
                placeholder="请输入Internship招聘链接"
              ></el-input>
            </el-form-item>
            <el-form-item label="Career Link">
              <el-input
                v-model="editResourceForm.careerLink"
                placeholder="请输入Carrer招聘链接"
              ></el-input>
            </el-form-item>
            <el-form-item label="New Grad Salary/y">
              <el-input
                type="number"
                v-model="editResourceForm.newGradSalary"
                placeholder="请输入NewGrad年薪"
              ></el-input>
            </el-form-item>
            <el-form-item label="Internship Salary/m">
              <el-input
                type="number"
                v-model="editResourceForm.internshipMonthlySalary"
                placeholder="请输入Internship月薪"
              ></el-input>
            </el-form-item>
            <el-form-item label="Internship Salary/h">
              <el-input
                type="number"
                v-model="editResourceForm.internshipHourlySalary"
                placeholder="请输入Internship时薪"
              ></el-input>
            </el-form-item>
            <el-form-item label="其他信息">
              <el-input
                v-model="editResourceForm.additionalRemarks"
                placeholder="请输入其它信息"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 20 }"
              ></el-input>
            </el-form-item>
          </el-form>
          <div style="text-align: center">
            <el-button
              type="primary"
              @click="publishForm"
              :disabled="buttonDisabled"
              >提交发布</el-button
            >
          </div>
        </div>
        <div class="switchForm">
          <div
            :class="['tabs', type == 'newCompany' ? 'active' : '']"
            @click="
              type = 'newCompany';
              searchCompanyKey = '';
            "
          >
            提供新的公司
          </div>
          <div
            :class="['tabs', type == 'newResource' ? 'active' : '']"
            @click="
              type = 'newResource';
              searchCompanyKey = '';
            "
          >
            提供求职最新信息
          </div>
          <div
            :class="['tabs', type == 'editResource' ? 'active' : '']"
            @click="
              type = 'editResource';
              searchCompanyKey = '';
            "
          >
            补充更正已有公司信息
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { searchCompanys } from "@/service/company";
import {
  createCompany,
  postSuggestion,
  editCompany,
} from "@/service/provideResource";
import _ from "lodash";
export default {
  name: "provideResource",
  data() {
    return {
      type: "",
      newCompanyForm: {},
      newResourceForm: {
        jobType: 0,
        type: 1,
      },
      editResourceForm: {},
      companys: [],
      timeout: null,
      searchCompanyKey: "",
      buttonDisabled: false,
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    if (this.$route.query.companyId && this.$route.query.companyName) {
      this.editResourceForm.companyId = this.$route.query.companyId;
      this.searchCompanyKey = this.$route.query.companyName;
    }
  },
  methods: {
    querySearch(queryString, cb, type) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.companys);
      }, 1000);
    },
    searchLodash: _.debounce(function () {
      this.searchCompanys();
    }, 1000),
    searchCompanys() {
      searchCompanys(this.searchCompanyKey).then((res) => {
        if (res.success) {
          this.companys = res.result;
        }
      });
    },
    selectCompany(option) {
      this.searchCompanyKey = option.name;
      this.editResourceForm.companyId = option.id;
    },
    selectDefaultCompany() {
      if (this.companys.length > 0) {
        this.searchCompanyKey = this.companys[0].name;
        this.editResourceForm.companyId = this.companys[0].id;
      } else {
        this.editResourceForm.companyId = "";
      }
    },
    blurEvents(type) {
      if (this.companys.length > 0) {
        this.searchCompanyKey = this.companys[0].name;
        if (type == "edit") {
          this.editResourceForm.companyId = this.companys[0].id;
        } else {
          this.newResourceForm.companyId = this.companys[0].id;
        }
      } else {
        this.searchCompanyKey = "";
        if (type == "edit") {
          this.editResourceForm.companyId = "";
        } else {
          this.newResourceForm.companyId = "";
        }
      }
    },
    publishForm() {
      if (this.type == "newCompany") {
        if (this.newCompanyForm.name == "") {
          return this.$message.error("请输入公司名称!");
        }
        createCompany(this.newCompanyForm).then((res) => {
          if (res.success) {
            this.$message.success("提交成功！");
            this.$router.push("/companyList");
          }
        });
      } else if (this.type == "newResource") {
        if (
          !this.newResourceForm.companyId ||
          this.newResourceForm.companyId == ""
        ) {
          return this.$message.error("请选择公司！");
        }
        if (this.newResourceForm.content == "") {
          return this.$message.error("请填写最新信息！");
        }
        postSuggestion(this.newResourceForm).then((res) => {
          if (res.success) {
            this.$message.success("提交成功！");
            this.$router.push("/companyList");
          }
        });
      } else if (this.type == "editResource") {
        if (
          !this.editResourceForm.companyId ||
          this.editResourceForm.companyId == ""
        ) {
          return this.$message.error("请选择公司！");
        }
        editCompany(this.editResourceForm).then((res) => {
          if (res.success) {
            this.$message.success("提交成功！");
            this.$router.push("/companyList");
          }
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.cson-container {
  width: 1200px;
  min-height: 800px;
  padding: 20px 0;
}
.formContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.title {
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  position: relative;
  padding-left: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}
.title::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 20px;
  background: #fa6400;
  border-radius: 2px;
  left: 0;
  top: 0;
}
.switchForm {
  padding: 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 300px;
  text-align: center;
  height: 158px;
  .tabs {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #757575;
    line-height: 36px;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid #f0f0f0;
    }
    &.active {
      color: #0a7aff;
    }
  }
}
.formDetail {
  width: calc(100% - 320px);
  min-height: 700px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px 40px;
}
.el-radio.is-bordered {
  ::v-deep .el-radio__input {
    display: none;
  }
  ::v-deep .el-radio__label {
    padding-left: 0;
  }
}
::v-deep .el-radio--small.is-bordered {
  padding: 8px 10px;
  border-radius: 16px;
}
::v-deep .el-radio {
  margin-right: 0px;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>